.about-section {
  h4 {
    font-size: 2rem;
  }

  .education-skills-container {
    display: flex;
    flex-direction: row; /* Horizontal layout by default */
    gap: 20px;
    flex-wrap: wrap; /* Ensure items wrap if necessary */

    .skills-column {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1; /* Ensure columns take equal space */

      .skill-card {
        background: var(--bg-primary);
        padding: 1rem;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        h4 {
          display: flex;
          align-items: center;
          color: var(--text-primary);
          gap: 10px;
        }
      }
    }
  }

  .programming-icons {
    display: flex;
    gap: 10px;

    svg {
      margin-left: 2rem;
      width: 3.5rem;
      height: 3.5rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .education-skills-container {
    flex-direction: column; /* Vertical layout on smaller screens */

    .skills-column {
      width: 100%; /* Ensure full width */
    }
  }
}
