.circle-button {
    border: 0.2rem solid var(--text-primary);
    color: var(--text-primary);
    background: var(--bg-primary);
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    margin: 0.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    transition: all 0.5s;

    &:hover {
        color: var(--bg-primary);
        background: var(--text-primary);
    }

    &:first-child {
        margin-left: 2px;
        margin-top: 100px;
    }

    &:last-child {
        margin-right: 0;
    }

    @media (max-width: 768px) {
        border: 0.1rem solid var(--text-primary); // Example change for smaller screens
        padding: 0.25rem; // Example change for smaller screens
        margin: 0.25rem; // Example change for smaller screens

        &:first-child {
            margin-left: 1px; // Example change for smaller screens
            margin-top: 50px; // Example change for smaller screens
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
