.blog-section {
    padding: 2rem 0;
    background-color: var(--bg-primary);
    color: var(--text-primary);
  
    h2 {
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 2rem;
    }
  
    .blogs-container {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      justify-content: center;
    }
  
    .blog {
      width: 300px;
      // border: 1px solid var(--text-secondary);
      border-radius: 10px;
      overflow: hidden;
    //   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;
  
      &:hover {
        transform: translateY(-5px);
      }
  
      a {
        text-decoration: none;
        color: inherit;
  
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
  
        .blog-info {
          padding: 1rem;
  
          h3 {
            font-size: 1.5rem;
            margin: 0.5rem 0;
          }
  
          p {
            font-size: 1rem;
            margin: 0;
          }
        }
      }
    }
  }
  