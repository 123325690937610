.menu {
    position: absolute;
    top: 10px;
    .menu-backdrop {
        display: none;
    }
}

.menu-active {

    .menu-backdrop {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        z-index: 99;
    }

    .menu-data {
        z-index: 1000;
    }
}

