// src/components/CareerSection/CareerSection.scss

.career-section {
    // max-width: 800px;
    margin: 5rem auto;
    padding: 2rem;
    // background: #f9f9f9;
    // border-radius: 12px;
    position: relative;
  
    h2 {
      text-align: center;
      font-size: 2.5rem;
      color: var(--text-primary);
      margin-bottom: 2rem;
    }
  
    .timeline {
      position: absolute;
      top: 8rem;
      bottom: 2rem;
      left: 50%;
      width: 4px;
      background: var(--text-primary);
      transform: translateX(-50%);
    }
  
    .jobs-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
  
      .job {
        width: 45%;
        // margin-bottom: 2rem;
        padding: 0.1rem 1.5rem;
        // background: #fff;
        // border-radius: 8px;
        transition: transform 0.3s ease-in-out;
        position: relative;
        z-index: 1;
  
        &:hover {
          transform: translateY(-5px);
        }
  
        &:nth-of-type(odd) {
          margin-right: 55%;
        //   align-self: flex-start;
        }
  
        &:nth-of-type(even) {
          margin-left: 55%;
        //   align-self: flex-end;
        }
  
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: -26px;
          transform: translateY(-50%);
          width: 30px;
          height: 20px;
          background: var(--text-primary);
        //   border-radius: 50%;
          z-index: -1;
        }
  
        &:nth-of-type(even)::before {
          left: auto;
          left: -26px;
        }
  
        h3 {
          font-size: 1.4rem;
          color: var(--text-primary);
          margin-bottom: 0.5rem;
        }
  
        .time-location {
          font-size: 0.9rem;
        //   color: #666;
          margin-bottom: 1rem;
        }
  
        ul {
          list-style: disc;
          padding-left: 1.5rem;
  
          li {
            font-size: 0.9rem;
            // color: #333;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  
    @media screen and (max-width: 768px) {
      .jobs-container {
        left: 30px;
        .job {
          width: 100%;
          margin-left: 0 !important;
          margin-right: 0 !important;
  
          &:nth-of-type(odd),
          &:nth-of-type(even) {
            margin-right: 0;
            margin-left: 0;
          }
  
          &::before {
            // left: 50%;
            // transform: translateX(-50%) translateY(-50%);
            display: none;
          }
        }
      }
  
      .timeline {
        left: 10%;
        transform: translateX(-10%);
      }
    }
  }
  