.menu-toggle {
    position: fixed;
    top: 50%;
    left: 2rem;
    background-color: var(--bg-primary);
    transform: translateY(-700%);
    z-index: 200;

    .menu-text {
        font-weight: bold;
        line-height: 0.8rem;
    }

    .menu-burger {
        padding-left: 0.3rem;

        .menu-burger-bar {
            display: block;
            width: 1.2rem;
            height: 0.2rem;
            background: var(--text-primary);
            margin-bottom: 0.3rem;
            border-radius: 1rem;
            transition: all 0.5s;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &:hover {

        .menu-burger-bar {
            background: var(--bg-primary);
        }
    }
}

.menu-active {

    .menu-toggle {

        .menu-burger-bar:nth-child(1) {
            transform: rotateZ(-55deg) translateY(0.9em) translateX(-0.5em);
            width: 1.2rem;
        }

        .menu-burger-bar:nth-child(2) {
            transform: rotateZ(90deg) translateY(0em) translateX(0.2em);
        }

        .menu-burger-bar:nth-child(3) {
            transform: rotateZ(60deg) translateY(0.2em) translateX(-0.4em);
            width: 1.2rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .menu-toggle {
        left: 1rem;
        top: 1rem;
        transform: translateY(0);
    }
}