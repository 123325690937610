.top-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 80vh;

    .intro {
        text-align: right;
        width: 50%; // Change width to 50% to make it half the width
        padding-right: 2em; // Adjust padding
        border-right: 1px solid var(--text-primary);

        h1 {
            font-size: 5rem; // Adjust font size
            font-weight: bold;
            line-height: 1em; // Adjust line height
            margin: 0;
        }

        p {
            font-size: 1.5em; // Adjust font size
            margin: 0;
        }
    }

    .profile-image {
        text-align: left;
        width: 50%; 
        padding-left: 2em; // Adjust padding

        img {
            max-width: 100%; // Make the image responsive
            height: auto; // Maintain aspect ratio
        }
    }

    
}
.social {
    text-align: center;
    .circle-button {
        transition: transform 0.3s ease-out;

        &:hover {
            transform: scale(1.5); /* Scale up on hover */
        }
    }
}

@media screen and (max-width: 1300px) {
    .top-section {

        .intro {
            width: 50%;

            h1 {
                font-size: 7rem;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .top-section {
        flex-direction: column;

        .intro {
            text-align: center;
            // border-bottom: 1px solid var(--text-primary);
            border-right: none;
            width: auto;
            padding: 0;
            padding-bottom: 2em;

            h1 {
               font-size: 5rem;
            }
        }

        .profile-image {
            height: 40%;
            padding: 0;
            padding-top: 2em;
        }
    }
}


@media (max-width: 768px) {
    .top-section {
        flex-direction: column; 
        align-items: stretch; 

        .intro {
            order: 2; 
            border-right: none; 
            text-align: center;
            width:100%;
        }

        .profile-image {
            width: 100%;
            display: block; 
            order: 1; 
            text-align: center;
        }
    }

    .social{
        margin-top: 20px;
    }
}