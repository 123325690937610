// Importing fonts
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

// Variables for light theme
:root {
    --bg-primary: #fafafa;
    --text-primary: #000000;
    // --text-primary: #425d81;
    --text-secondary: #e0e0e0;
    
    font-size: 16px;
}

// Variables for dark theme
.dark-mode {
    --bg-primary: #2f2f2f;
    --text-primary: #ffffff;
    --text-secondary: #e0e0e0;
}

body {
    padding: 0;
    margin: 0;
    background: var(--bg-primary);
    color: var(--text-primary);
    font-family: 'Varela Round', sans-serif;
    font-size: 16px;
    // Transition for dark mode
    transition: all 0.2s;
}

input, textarea, button {
    font-family: inherit;
}

.body-wrapper {
    width: 1100px;
    max-width: 90%;
    margin: 0 auto;
}

a {
    color: var(--text-primary);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.MuiTooltip-tooltip {
    font-size: 1em !important;
}

::-webkit-scrollbar {
    width: 0.3rem;
}

::-webkit-scrollbar-track {
    background: transparent;
}
  
::-webkit-scrollbar-thumb {
    background: var(--text-primary);
    border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--text-secondary);
}
