// src/components/ContactForm/ContactForm.scss
.contact-form-wrapper {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    // background: #fff;
    border-radius: 8px;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    h2 {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 1.5rem;
      color: var(--text-primary);
    }
  
    .contact-form {
      .form-group {
        margin-bottom: 1.5rem;
  
        label {
          display: block;
          font-weight: bold;
          margin-bottom: 0.5rem;
          color: var(--text-primary);
  
          // &::after {
          //   content: '*';
          //   color: #e74c3c;
          //   margin-left: 0.25rem;
          // }
        }
  
        input,
        textarea {
          width: 100%;
          padding: 0.75rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 1rem;
          color: var(--text-primary);
          background: var(--bg-primary);
          transition: border-color 0.3s;
  
          &.error {
            border-color: #e74c3c;
          }
        }
  
        .error-text {
          color: #e74c3c;
          font-size: 0.875rem;
          margin-top: 0.5rem;
        }
      }
  
      .submit-button {
        display: inline-block;
        width: 100%;
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
        font-weight: bold;
        color: var(--bg-primary);
        background-color: var(--text-primary);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:disabled {
          // background-color: #ccc;
        }
  
        &:hover:not(:disabled) {
          // background-color: (var(--text-primary), 10%);
        }
      }
  
      .success-message {
        margin-top: 1.5rem;
        color: #27ae60;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
  